import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				La Dolce Pizza
			</title>
			<meta name={"description"} content={"Ihr perfektes Stück erwartet Sie"} />
			<meta property={"og:title"} content={"La Dolce Pizza"} />
			<meta property={"og:description"} content={"Ihr perfektes Stück erwartet Sie"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-2.jpg?v=2024-07-11T10:42:28.094Z) 50% 60%/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					bei der jedes Stück ein Fest des Geschmacks und der Tradition ist
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="50%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--base" text-transform="uppercase">
							Unser Engagement für Spitzenleistung stellt sicher, dass jede Pizza, die wir kreieren, ein Meisterwerk ist, das mit Liebe und den besten Zutaten hergestellt wird.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex" />
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					La Dolce Pizza wurde mit der Vision gegründet, unserer Gemeinde den authentischen Geschmack Italiens zu bringen.
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10:42:28.096Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-1.jpg?v=2024-07-11T10%3A42%3A28.096Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10:42:28.069Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-2.jpg?v=2024-07-11T10%3A42%3A28.069Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Unsere Leidenschaft für Pizza spiegelt sich in jedem Aspekt unseres Restaurants wider, von unseren sorgfältig ausgewählten Zutaten bis hin zu unseren handwerklichen Zubereitungsmethoden.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10:42:28.118Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/1-3.jpg?v=2024-07-11T10%3A42%3A28.118Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Unsere erfahrenen Köche, die in den traditionellen Techniken der italienischen Pizzaherstellung ausgebildet sind, stellen sicher, dass jede Pizza, die aus unserem Ofen kommt, ein Beweis für unser Engagement für Qualität und Geschmack ist.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 3rem 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Warum La Dolce Pizza wählen?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Unsere Speisekarte bietet traditionelle italienische Rezepte, die über Generationen weitergegeben wurden und so ein authentisches Speiseerlebnis gewährleisten.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Wir legen Wert auf die Verwendung frischer, hochwertiger Zutaten, um Pizzen zu kreieren, die sowohl köstlich als auch nahrhaft sind.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Unsere Pizzeria ist so gestaltet, dass sie eine gemütliche, familienfreundliche Umgebung bietet, in der sich jeder willkommen fühlt.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Unser freundliches Personal ist bestrebt, erstklassigen Service zu bieten und sicherzustellen, dass Ihr Besuch angenehm und unterhaltsam ist.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Wir bieten eine große Auswahl an Pizzas und anderen italienischen Gerichten für verschiedene Geschmäcker und Ernährungsvorlieben, darunter auch vegetarische und glutenfreie Optionen.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://uploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10:42:28.060Z"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668fb62d8d4a0c0020318fe8/images/2-3.jpg?v=2024-07-11T10%3A42%3A28.060Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				align-self="center"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Probieren Sie die Tradition
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Bei La Dolce Pizza glauben wir, dass großartige Pizza mit großartigen Zutaten beginnt. Deshalb beziehen wir die frischesten Produkte, das feinste Fleisch und den schmackhaftesten Käse sowohl von lokalen Lieferanten als auch von vertrauenswürdigen italienischen Importeuren. Unser Teig wird täglich frisch hergestellt, langsam aufgehen gelassen, um eine leichte, luftige Textur zu erhalten, und in unserem Holzofen perfekt gebacken. Das Ergebnis ist eine Pizza, die nicht nur fantastisch schmeckt, sondern auch die Essenz des italienischen kulinarischen Erbes einfängt.
				</Text>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Text margin="0px 0px 0px 0px" color="--dark" font="600 48px --fontFamily-sans">
				Bei La Dolce Pizza glauben wir, dass gutes Essen Menschen zusammenbringt.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1579751626657-72bc17010498?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Klassische Pizzas
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1621996346565-e3dbc646d9a9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Klassische Pastas
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1589977527647-32183d9f0e63?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Antipasti
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
							srcSet="https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1639744211487-b27e3551b07c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Traditionelle Desserts
						</Text>
					</Box>
				</Box>
			</Box>
			<Button
				align-self="center"
				margin="1rem 0px 0px 0px"
				type="link"
				href="/services"
				text-decoration-line="initial"
			>
				Zum Menü
			</Button>
		</Section>
		<Components.Footer23 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});